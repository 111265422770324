import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const IntersectionObserverProvider = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        const handleIntersection = (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                    observer.unobserve(entry.target);
                }
            });
        };

        const fadeInElements = document.querySelectorAll('.fade-in');
        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.2 });

        fadeInElements.forEach(element => {
            observer.observe(element);
        });

        return () => {
            observer.disconnect();
        };
    }, [location.pathname]);

    return <>{children}</>;
};

export default IntersectionObserverProvider;

import React from 'react';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex h-screen mb-12 sm:mb-0 w-full'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/permacultuur/header1.jpeg'}
                    alt={'Groenten in een permacultuur tuin'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='self-stretch flex-col justify-start items-center gap-14 flex'>
                <div className='container px-5 sm:px-12 mx-auto'>
                    <div className='mb-6 lg:mb-12'>
                        <h2 className='leading-7 text-center text-dark fade-in sm:mb-2'>Succesvol verstuurd
                        </h2>
                        <p className={'text-dark text-center fade-in'}>Uw bericht is succesvol verstuurd en ontvangen, we komen zo snel mogelijk bij u terug.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

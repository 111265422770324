import React, { useState } from "react";
import {Link} from "react-router-dom";

export default function ({ data, full, obs }) {
    const [slide, setSlide] = useState(0);

    const nextSlide = () => {
        setSlide(slide === data.length - 1 ? 0 : slide + 1);
    };

    const prevSlide = () => {
        setSlide(slide === 0 ? data.length - 1 : slide - 1);
    };

    const [touchStart, setTouchStart] = useState({ x: null, y: null });

    const handleTouchStart = (e) => {
        setTouchStart({ x: e.touches[0].clientX, y: e.touches[0].clientY });
    };

    const handleTouchMove = (e) => {
        if (touchStart.x !== null) {
            const touchEndX = e.touches[0].clientX;
            const touchEndY = e.touches[0].clientY;

            const deltaX = touchEndX - touchStart.x;
            const deltaY = touchEndY - touchStart.y;

            // Check if the horizontal movement is greater than the vertical movement
            if (Math.abs(deltaX) > Math.abs(deltaY)) {
                if (deltaX > 0) {
                    prevSlide();
                } else if (deltaX < 0) {
                    nextSlide();
                }
            }

            setTouchStart({ x: null, y: null });
        }
    };

    return (
        <div
            className={`relative flex justify-center items-center h-full w-full`}
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
        >
            {data.map((item, idx) => (
                <React.Fragment key={idx}>
                    <img
                        src={`/images/${item.src}`}
                        alt={item.alt}
                        className={`duration-300 absolute object-cover h-full w-full ${full && 'brightness-50'}`}
                        style={{
                            transform: `translateX(${slide === idx ? '0%' : slide > idx ? '-100%' : '100%'})`,
                        }}
                    />
                    {full && (
                        <div
                            className="absolute text-center duration-300 h-full w-full flex flex-col justify-center items-center"
                            style={{
                                transform: `translateX(${slide === idx ? '0%' : slide > idx ? '-100%' : '100%'})`,
                            }}>
                            <h1 className="text-light text-4xl sm:text-5xl font-semibold">{item.title}</h1>
                            <h2 className="text-center text-light font-light my-2.5">{item.locatie}</h2>
                            <Link
                                to={`/projecten/${item.path}`}
                                className="px-7 py-2.5 rounded-full border border-lime-700 text-light text-sm sm:text-base font-medium hover:bg-green duration-300">
                                Meer Info
                            </Link>
                        </div>
                    )}
                </React.Fragment>
            ))}
            {!full && !obs && <div className="gradient-overlay gradient-overlay-white"/>}
            {obs === 'first' ? <div className="gradient-overlay gradient-overlay-obs-first"/> : obs === 'mid' && <div className="gradient-overlay gradient-overlay-obs-mid"/> }
            <div className="flex justify-center items-center">
                <div
                    className={`${obs ? 'bottom-28' : 'bottom-14'} absolute items-center gap-3 inline-flex`}>
                    {data.map((_, idx) =>
                        (data.length < 6 || (data.length > 6 && ((slide > (idx - 3) && slide < (idx + 3)))) || (idx < 6 && slide < 3) || (slide > data.length - 4 && idx > data.length - 7)) ? (
                            <button
                                key={idx}
                                role={'button'}
                                aria-label={'Carousel navigatie'}
                                className={`w-3.5 h-3.5 bg-opacity-50 rounded-full border border-white ${slide === idx ? "bg-neutral-600" : "bg-zinc-300"}`}
                                onClick={() => setSlide(idx)}
                            />
                        ) : ((slide > 2 && idx === (slide - 3)) || (slide < (data.length - 3) && idx === (slide + 3)) || (slide < 3 && idx < 7) || (slide > data.length - 4 && idx === data.length - 7)) && (
                            <button
                                key={idx}
                                role={'button'}
                                aria-label={'Carousel navigatie'}
                                className={`w-2 h-2 bg-opacity-50 rounded-full border border-white ${slide === idx ? "bg-neutral-600" : "bg-zinc-300"}`}
                                onClick={() => setSlide(idx)}
                            />
                        )
                    )}
                </div>
            </div>
            <div
                className={`w-20 h-20 right-5 absolute cursor-pointer hidden sm:block`}
                onClick={nextSlide}>
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
                <path
                        d="M37.5 68.75C54.7589 68.75 68.75 54.7589 68.75 37.5C68.75 20.2411 54.7589 6.25 37.5 6.25C20.2411 6.25 6.25 20.2411 6.25 37.5C6.25 54.7589 20.2411 68.75 37.5 68.75Z"
                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M37.5 50L50 37.5L37.5 25" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M25 37.5H50" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
            <div
                className={`w-20 h-20 left-5 absolute cursor-pointer hidden sm:block`}
                onClick={prevSlide}>
                <svg xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75" fill="none">
                    <path
                        d="M37.5 68.75C54.7589 68.75 68.75 54.7589 68.75 37.5C68.75 20.2411 54.7589 6.25 37.5 6.25C20.2411 6.25 6.25 20.2411 6.25 37.5C6.25 54.7589 20.2411 68.75 37.5 68.75Z"
                        stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M37.5 25L25 37.5L37.5 50" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                    <path d="M50 37.5H25" stroke="white" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"/>
                </svg>
            </div>
        </div>
    );
}

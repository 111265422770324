import React from 'react';
import Carousel from '../Carousel';
import carousel from './korenmaat.json';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex w-full mb-12 sm:mb-24'>
            <div className={'h-[700px] w-full'}>
                <Carousel data={carousel.slides}/>
            </div>
            <div className={'max-w-screen-xl px-5 sm:px-12 mx-auto'}>
                <div className='mb-12 sm:mb-24 fade-in'>
                    <h2 className={'text-center mb-6'}>De Korenmaat
                    </h2>
                    <p>De permacultuur leertuin bij ‘Stadsboerderij de Korenmaat’ is een voorbeeldtuin die tevens
                        voorziet
                        in voedsel voor de dierotheek, boerderijdieren en de wijkbewoners.
                        In maart 2016 zijn we begonnen met het aanleggen van deze permacultuur tuin. De tuin laat zien
                        hoe
                        je eenvoudig fruitbomen, fruitstruiken, (eetbare) bloemen, kruiden en groenten kunt combineren.
                        Waarbij je toch een leuk ogende tuin hebt.
                    </p>
                </div>
                <div className='mb-12 sm:mb-24 justify-center 2xl:items-center lg:inline-flex fade-in'>
                    <div>
                        <img className='rounded-t-lg lg:rounded-lg w-full' alt={'Overzicht foto De Korenmaat'}
                             src='/images/projecten/korenmaat/image1.jpeg'/>
                    </div>
                    <div className='lg:ml-[-100px] 2xl:mt-0 lg:mt-40 flex-col items-end inline-flex'>
                        <div
                            className='p-6 bg-green rounded-b-lg lg:rounded-lg'>
                            <p
                                className='text-light'>Het
                                ontwerp is zo gemaakt dat er in het midden van alle bedden éénjarige groenten of bloemen
                                geteeld kunnen worden. Hier omheen staan de meerjarige kruidenstruiken en de vaste
                                fruitstruiken.
                                En als laatste staan daar nog de fruitbomen omheen, de hogere klimstruiken zoals braam,
                                druif, kiwi
                                en is er een mogelijkheid om hogere éénjarige teelt te zaaien zoals bijv. maïs. De tuin
                                is op het
                                zuiden gericht om zo veel mogelijk zonlicht en warmte op te vangen, zodat de teelt
                                zolang mogelijk
                                door kan gaan.
                            </p>
                        </div>
                        <a
                            className='px-6 py-2.5 mt-[-25px] mr-12 hover:scale-105 duration-300 bg-stone-100 rounded-lg shadow items-center gap-2.5 inline-flex text-dark text-sm sm:text-lg font-semibold'
                            rel='noreferrer' target={'_blank'} href={'https://www.youtube.com/watch?v=v7KRfMf3lD4'}>Video
                            <svg xmlns='http://www.w3.org/2000/svg' width='24' height='25' viewBox='0 0 24 25'
                                 fill='none'>
                                <path d='M5 12.5H19' stroke='black' strokeWidth='2' strokeLinecap='round'
                                      strokeLinejoin='round'/>
                                <path d='M12 5.5L19 12.5L12 19.5' stroke='black' strokeWidth='2'
                                      strokeLinecap='round' strokeLinejoin='round'/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className='mb-12 sm:mb-24 fade-in'>
                    <p>Bij het bedenken van het ontwerp van deze tuin moesten we rekening houden met een aantal
                        doelen, zodat de tuin op allerlei manieren een functie heeft.<br/><br/>
                        Doelen:
                        <ul>
                            <li>Voedsel voor de dierotheek en boerderijdieren.</li>
                            <li>Kruiden, fruit en groenten voor de boerderijwinkel.</li>
                            <li>Soepgroenten voor één van de lessen van het Natuurcentrum.</li>
                            <li>Informatieplek over de permacultuur-principes en hoe hiermee aan de slag te gaan.</li>
                            <li>Een plek waar natuur- en teeltlessen gegeven kunnen worden aan kinderen en
                                volwassenen.
                            </li>
                            <li>Een ontspannings- en begeleidingsplek voor diverse doelgroepen.</li>
                        </ul>
                    </p>
                </div>
                <div className={'fade-in'}>
                    <img className='self-stretch rounded-lg' alt={'Persoon aan het werk in de tuin'}
                         src='/images/projecten/korenmaat/image2.jpeg'/>
                    <div className='w-11/12 sm:w-3/4 mt-[-25px] sm:mt-[-100px] m-auto'>
                        <div className='p-6 bg-green rounded-lg inline-flex'>
                            <p
                                className='text-light'>Het
                                onderhoud van de tuin wordt door verschillende doelgroepen gedaan.
                                Onder begeleiding helpen mensen met een rugzakje, buurtbewoners, asielzoekers en
                                groepen van
                                bedrijven mee met het onderhouden van de tuin. Soms letterlijk met de handen in de aarde
                                en soms
                                met het maken van bijv. compostbakken, steunrekken of een afdakje.
                            </p>
                        </div>
                        <div className='flex-col items-end flex mt-[-25px] mr-12'>
                            <a
                                className='px-7 py-2.5 bg-stone-100 rounded-lg shadow justify-center hover:scale-105 duration-300 items-center gap-2.5 inline-flex text-dark text-sm sm:text-lg font-semibold'
                                rel={'noreferrer'} target={'_blank'}
                                href={'https://www.youtube.com/watch?v=xZ6G9t20SO0'}>Video
                                <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'
                                     fill='none'>
                                    <path d='M5 12H19' stroke='#323233' strokeWidth='2' strokeLinecap='round'
                                          strokeLinejoin='round'/>
                                    <path d='M12 5L19 12L12 19' stroke='#323233' strokeWidth='2' strokeLinecap='round'
                                          strokeLinejoin='round'/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';

export default function() {
    return (
        <div className='bg-white mb-12 sm:mb-24 w-full'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/projecten/natuurclub/header.jpeg'}
                    alt={'Groenten in een permacultuur tuin'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='max-w-screen-2xl px-6 sm:px-12 mx-auto mb-12 sm:mb-24 fade-in'>
                <div className='mb-6 lg:mb-12'>
                    <h2 className='leading-7 text-center text-dark sm:mb-2'>Kids Natuurclub
                    </h2>
                    <p className={'text-center leading-5 italic text-dark'}>een project van NatuurPlezier</p>
                </div>
                <div className='gap-12 grid grid-cols-1 lg:grid-cols-2 items-center'>
                    <p className={'text-dark'}>Vind jij het leuk om allerlei dingen te doen in en met de
                        natuur? Of weet je dat nog niet, maar wil je het graag uitproberen? Kom dan gezellig naar de
                        Kids Natuurclub. We gaan met een vast groepje kinderen een heleboel leuke dingen doen op
                        stadsboerderij De Korenmaat.
                    </p>
                    <img className='rounded-lg max-h-96 w-full object-cover'
                         src={'/images/projecten/natuurclub/image1.jpeg'}
                         alt={'Kinderen in een permacultuur-leertuin'}/>
                </div>
            </div>
            <div className='items-end xl:items-center lg:inline-flex w-full fade-in'>
                <img className='rounded-r-lg xl:w-3/4' alt={'Kinderen aan het spelen in het moeras'}
                     src='/images/projecten/natuurclub/image2.jpeg'/>
                <div
                    className='px-6 py-14 sm:px-12 lg:p-24 lg:pl-36 lg:pr-12 xl:pr-24 xl:w-2/4 bg-green lg:opacity-90 lg:mb-[-350px] xl:mb-0 xl:opacity-100 lg:rounded-l-full lg:absolute lg:right-0'>
                    <h3 className='text-light mb-3'>Wat gaan
                        we doen?
                    </h3>
                    <p className='text-light lg:max-w-lg xl:max-w-3xl'>– natuurlogboek
                        maken<br/>– knutselen met natuurlijk materiaal en “afval”<br/>– kruidenazijn maken<br/>–
                        groenten en bloemen zaaien en oogsten<br/>– plantjes poten en eigen zaaibakjes maken<br/>–
                        kleuren met bloemen<br/>– hutten bouwen<br/>– spelen in en rond het moeras<br/>– waterbeestjes
                        zoeken in de poel bij de boerderij<br/>– eetbare planten/kruiden zoeken, leren herkennen en
                        proeven<br/>– ….en nog veel meer
                    </p>
                </div>
            </div>
            <div className='items-end xl:items-center lg:inline-flex w-full justify-end fade-in'>
                <img className='lg:hidden' alt={'Kinderen diertjes aan het onderzoeken'}
                     src='/images/projecten/natuurclub/image3.jpeg'/>
                <div
                    className='px-6 py-14 sm:px-12 lg:p-24 xl:w-2/4 bg-green lg:opacity-90 lg:mb-[-140px] xl:mb-0 xl:opacity-100 lg:rounded-r-full lg:absolute lg:left-0'>
                    <h3 className='lg:text-right text-light mb-3'>Voor
                        wie?
                    </h3>
                    <p className='lg:text-right text-light lg:max-w-lg xl:max-w-3xl'>Kinderen in
                        de leeftijd van 7-9 jaar.
                    </p>
                </div>
                <img className='rounded-l-lg w-3/4 hidden lg:block' alt={'Kinderen diertjes aan het onderzoeken'}
                     src='/images/projecten/natuurclub/image3.jpeg'/>
            </div>
            <div className='items-end xl:items-center lg:inline-flex w-full fade-in'>
                <img className='rounded-r-lg xl:w-3/4' alt={'Kinderen aan het spelen op mesthoop'}
                     src='/images/projecten/natuurclub/image4.jpeg'/>
                <div
                    className='px-6 py-14 sm:px-12 lg:p-24 xl:w-2/4 bg-green lg:opacity-90 lg:mb-[-230px] xl:mb-0 xl:opacity-100 lg:rounded-l-full lg:absolute lg:right-0'>
                    <h3 className='text-light mb-3'>Waar en wanneer?
                    </h3>
                    <p className='text-light lg:max-w-lg xl:max-w-3xl'>In de permacultuur-leertuin van stadsboerderij De
                        Korenmaat,
                        Marius van Beeklaan 5, Arnhem. Één keer per week komt de
                        groep samen (dag en tijd in overleg, graag aangeven bij opgave). Je kunt op elk moment
                        starten in de groep, maar je moet je wel even aanmelden (zie hieronder). Een
                        groep bestaat uit maximaal 8 kinderen.
                    </p>
                </div>
            </div>
            <div className='items-end xl:items-center lg:inline-flex w-full justify-end fade-in'>
                <img className='lg:hidden' alt={'Kinderen plantjes aan het planten'}
                     src='/images/projecten/natuurclub/image5.jpeg'/>
                <div
                    className='px-6 py-14 sm:px-12 lg:p-24 xl:w-2/4 bg-green lg:opacity-90 lg:mb-[-190px] xl:mb-0 xl:opacity-100 lg:rounded-r-full lg:absolute lg:left-0'>
                    <h3 className='lg:text-right text-light mb-3'>Wat kost het?
                    </h3>
                    <p className='lg:text-right text-light lg:max-w-lg xl:max-w-3xl'>De kosten voor 8 lessen zijn 100 euro.
                        Dit is inclusief
                        materialen en drinken. Je kan een proefles nemen voor 12,50 euro om te kijken of je
                        het leuk vindt. Korting is mogelijk met de gelrepas.
                    </p>
                </div>
                <img className='rounded-l-lg xl:w-3/4 hidden lg:block' alt={'Kinderen plantjes aan het planten'}
                     src='/images/projecten/natuurclub/image5.jpeg'/>
            </div>
            <img className='xl:hidden' alt={'Door kinderen gemaakte waterval'}
                 src='/images/projecten/natuurclub/image6.jpeg'/>
            <div className='justify-center items-center w-full mt-[-48px] sm:mt-[-56px] xl:mt-[-62px] px-6 sm:px-12 fade-in'>
                <div className='px-5 py-2.5 bg-green rounded-t-lg flex items-center w-max mx-auto relative'>
                    <p className='text-light font-semibold'>Geïnteresseerd?</p>
                </div>
                <div className='px-6 sm:px-12 xl:px-36 py-12 bg-green rounded-2xl sm:rounded-full flex items-center mx-auto max-w-[1200px]'>
                    <div className='text-center'>
                        <p className='text-light'>
                            Deze lessen worden georganiseerd door NatuurPlezier. Voor vragen en aanmeldingen, neem
                            contact op met Ingeborg via tel.nr. 06–1212 2022 of via e-mail{' '}
                            <a className={'underline'} href={'mailto:idufijan@hotmal.com'}>idufijan@hotmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

import Carousel from '../Carousel';
import React from 'react';
import carousel from './carousel.json';

export default function() {
    return (
        <div className='h-screen w-full'>
            <Carousel data={carousel.slides} full />
        </div>
    );
}

import Carousel from '../Carousel';
import React from 'react';
import carousel from './carousel.json';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex mb-12 sm:mb-24 w-full'>
            <div className={'h-[700px] w-full'}>
                <Carousel data={carousel.slides} />
            </div>
            <div className='self-stretch flex-col justify-start items-center gap-14 flex'>
                <div className='container px-5 sm:px-12 mx-auto'>
                    <div className='mb-6 lg:mb-12'>
                        <h2 className='leading-7 text-center text-dark fade-in sm:mb-2'>Een leslokaal in de
                            natuur
                        </h2>
                        <p className={'text-center leading-5 italic text-dark fade-in'}>'Dat is toch het mooiste wat er is'</p>
                    </div>
                    <div className='gap-12 grid grid-cols-1 lg:grid-cols-2 items-center'>
                        <img className='rounded-lg max-h-96 w-full object-cover hidden lg:block fade-in' src={'/images/home/image.jpeg'} alt={'Kinderen in een permacultuur-leertuin'} />
                        <p className={'text-dark fade-in'}>Wij
                            ontwerpen, adviseren en leggen tuinen aan waarbij we op een speelse manier met de natuur en
                            haar
                            mogelijkheden omgaan. Alle soorten en maten zijn mogelijk ook school- en buurttuinen.
                            Tijdens de aanleg
                            hanteren we zo veel mogelijk de permacultuur principes om een zo gezond mogelijke tuin te
                            creëren. We
                            helpen en denken mee met de voortgang, het onderhoud en het gebruik van de tuinen in de
                            toekomst, zodat
                            de doelen die we gezamenlijk hebben opgesteld ook echt worden behaald (het liefst met veel
                            plezier).
                            Onze visie is om zoveel mogelijk samenwerking te creëren door middel van de natuur. En de
                            natuur zoveel
                            mogelijk in haar natuurlijke staat van zijn samen te brengen met de mens en zijn omgeving.
                        </p>
                        <img className='rounded-lg max-h-96 w-full block object-cover lg:hidden fade-in' src={'/images/home/image.jpeg'} alt={'Kinderen in een permacultuur-leertuin'} />
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from 'react';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex mb-12 sm:mb-24 w-full'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/projecten/stadslandbouw/header.jpeg'}
                    alt={'Groenten in een permacultuur tuin'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='self-stretch flex-col justify-start items-center gap-14 flex'>
                <div className='max-w-screen-2xl px-6 sm:px-12 mx-auto'>
                    <h2 className='leading-7 text-center text-dark fade-in mb-6 lg:mb-12'>Stadslandbouw</h2>
                    <div className='gap-12 grid grid-cols-1 lg:grid-cols-2 items-center'>
                        <p className={'text-dark fade-in'}>Deze ‘groene stadsoase’ kwam tot stand door een
                            crowdfundingsactie in 2017, met de hulp van veel buurtbewoners en een bijdrage van het
                            Oranjefonds. Het is daarom een plek van en voor de buurt. Als het hek open staat ben je er
                            van harte welkom.In de permacultuur tuin staan allerlei gewassen door elkaar. Daarmee
                            bootsen we zoveel mogelijk een natuurlijk ecosysteem na. Je vindt er een combinatie van
                            eetbare en niet-eetbare gewassen, vaste planten en eenjarigen, struiken, kruiden en bloemen.
                            Het duurt vaak meerdere jaren voordat de permacultuur tuin volwassen is en zichzelf goed in
                            evenwicht houdt.
                        </p>
                        <img className='rounded-lg max-h-96 w-full object-cover fade-in'
                             src={'/images/projecten/stadslandbouw/header.jpeg'} alt={'Kinderen in een permacultuur-leertuin'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

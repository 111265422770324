import React from "react";

export default function() {
    return (
        <footer
            className="self-stretch px-12 py-2.5 border-t border-green text-center">
            <p className="text-sm">© 2023
                Permacultuur-leertuinen. Alle rechten voorbehouden.
            </p>
        </footer>
    );
}

import React from 'react';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex w-full mb-12 sm:mb-24'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/permacultuur/header2.jpeg'}
                    alt={'Kind aan het werk in permacultuur-leertuin'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='max-w-screen-xl px-5 sm:px-12 mx-auto mb-12 sm:mb-24 fade-in'>
                <h2 className={'text-center mb-6'}>Wat is een permacultuur-leertuin?
                </h2>
                <p>Een permacultuur-leertuin is een permacultuur tuin bij een basisschool, die dient als een
                    buiten-leslokaal in een natuurlijke omgeving. Deze omgeving is zoveel mogelijk volgens de
                    permacultuur principes opgebouwd. Het leslokaal kan van alles bevatten, afhankelijk van de wensen,
                    bijvoorbeeld zitplekken/tafels, moestuin plekken, een grote diversiteit aan eetbare planten
                    (variërend van fruitbomen tot eetbare (on)kruiden) of zelfs een amfitheater.
                </p>
            </div>
            <div className='max-w-screen-xl px-5 sm:px-12 mb-12 sm:mb-24 fade-in'>
                <div className={'rounded-lg inline-flex overflow-hidden'}>
                    <img className='w-1/2 object-cover hidden lg:block'
                         src='/images/permacultuur/image7.jpeg'
                         alt={'Kinderen aan het leren in een permacultuur-leertuin.'}/>
                    <div
                        className='grow shrink basis-0 p-5 sm:p-12 bg-green flex-col justify-start items-start inline-flex'>
                        <h2 className={'text-light mb-5'}>Doel
                            leertuinen
                        </h2>
                        <p className={'text-light'}>Het doel van het plan om leertuinen aan te leggen bij basisscholen is veelzijdig:</p>
                        <ul className={'text-light'}>
                            <li><p className={'text-light'}>Kinderen worden gestimuleerd om te gaan ruiken en proeven van alles wat er in de tuin
                                groeit. Zo komen ze met groenten, fruit, kruiden en eetbare onkruiden in aanraking en raken
                                ze aan de vele verschillende smaken gewend. Uit het permacultuur-leertuin project bij Oog in
                                Al is al gebleken dat dit goed werkt.</p></li>
                            <li><p className={'text-light'}>Kinderen in de steden kunnen meer over natuur en moestuinieren leren.</p></li>
                            <li><p className={'text-light'}>Kinderen komen meer in beweging door werk- en spelactiviteiten in de tuin.</p></li>
                            <li><p className={'text-light'}>Diverse andere organisaties en projecten, zoals de tussen- en naschoolse opvang, wijk- en
                                buurtbewoners, kunst- en cultuurprojecten en bedrijven (t.b.v. team building) kunnen gebruik
                                van de tuin gaan maken, zodat de tuin maximaal benut wordt en een breed draagvlak
                                krijgt.</p></li>
                            <li><p className={'text-light'}>Gemeentegrond kan in participatie worden gegeven. Dit scheelt kosten voor de gemeente en
                                de grond wordt nuttig gebruikt.</p></li>
                            <li><p className={'text-light'}>VMBO- en MBO-scholen kunnen bij projecten betrokken worden voor stageplekken en allerlei
                                andere samenwerkingsmogelijkheden.</p></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='max-w-screen-xl px-5 sm:px-12 mx-auto mb-12 sm:mb-24 fade-in'>
                <p>Lessen en activiteiten die normaal binnen in de school plaatsvinden kunnen daar waar mogelijk
                    verhuizen naar de tuin en de tuin biedt een mogelijkheid om te moestuinieren. Dit vergt enige
                    creativiteit van de leerkrachten. Enkele voorbeelden van waar de tuin voor gebruikt kan worden zijn:
                    toneel voordrachten, spreekbeurten, verjaardagsmomenten, muziekmomenten, kosmisch onderwijs,
                    natuurlessen, moestuinieren, vergaderingen, tussen- en naschoolse opvangactiviteiten, oogst verkoop,
                    lekker thee drinken en proeven van alle kruiden en bloemen. Dit is nog maar een kleine greep uit
                    alles wat kinderen (en volwassenen) in de tuin kunnen doen.
                </p>
            </div>
            <div className='container px-5 sm:px-12 lg:inline-flex fade-in'>
                <div className='lg:pt-48 z-10 lg:mr-[-50px]'>
                    <div
                        className='p-5 sm:p-12 bg-green rounded-t-lg lg:rounded-lg flex-col justify-center items-start flex max-w-[900px]'>
                        <h2
                            className='text-light mb-5'>Effecten
                            op kinderen
                        </h2>
                        <p
                            className='text-light'>Contact met de natuur doet kinderen
                            goed: het prikkelt al hun zintuigen, brengt hen in beweging en daagt ze uit tot ‘verkennen’.
                            De laatste jaren wordt steeds meer onderzoek gedaan naar de effecten van natuurbeleving op
                            de ontwikkeling en het welzijn van kinderen (zie hierover de publicaties van prof. Agnes van
                            den Berg, Universiteit Wageningen/Alterra). Enkele vastgestelde gunstige effecten zijn:</p>
                        <ul className={'text-light'}>
                            <li><p className={'text-light'}>kinderen vertonen in een natuurlijke speelomgeving
                                gevarieerder en meer creatief en exploratief speelgedrag dan in een sporthal.</p></li>
                            <li><p className={'text-light'}>kinderen vertonen in de natuur een sterkere verbetering in
                                hun emotionele welzijn en stemming dan na deelname aan een dansprogramma.</p></li>
                            <li><p className={'text-light'}>kinderen met ADHD functioneren over het algemeen beter en
                                vertonen minder probleemgedrag in een natuurlijke dan in een niet-natuurlijke
                                omgeving.</p></li>
                        </ul>
                        <p className={'text-light'}>Daarnaast zijn o.a. de volgende positieve effecten van contact met
                            de natuur op mensen in het algemeen (kinderen én volwassenen) gemeten:</p>
                        <ul className={'text-light'}>
                            <li><p className={'text-light'}>tuinieren op een volkstuin leidt tot sneller en completer
                                herstel van stress dan een activiteit binnenshuis.</p></li>
                            <li><p className={'text-light'}>de aanwezigheid van groen in de woonomgeving blijkt een
                                positieve invloed op de algemene en mentale gezondheid van stadsbewoners te hebben en
                                acute gezondheidsklachten te verminderen;</p></li>
                            <li><p className={'text-light'}>een groene omgeving heeft een gunstig effect of de
                                gezondheid van vooral kinderen en jongeren (doordat zij i.t.t. volwassenen in een groene
                                omgeving meer bewegen).</p></li>
                        </ul>
                    </div>
                </div>
                <div className='lg:ml-[-50px]'>
                    <img className='rounded-b-lg lg:rounded-lg w-full max-w-[773px] max-h-[624px]'
                         src='/images/permacultuur/image8.jpeg' alt={'Kinderen aan het spelen in en met de natuur'}/>
                </div>
            </div>
        </div>
    );
}

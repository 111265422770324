import Carousel from '../Carousel';
import React from 'react';
import obs1 from './obs.json';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex w-full'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/projecten/obs/header.jpeg'}
                    alt={'Welkom bordje in permacultuur-leertuin OBS Oog in Al'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='self-stretch flex-col justify-start items-center flex'>
                <div
                    className='max-w-screen-xl px-6 sm:px-12 mb-12 sm:mb-24 fade-in'>
                    <h2
                        className='text-dark text-center mb-5'>Permacultuur-leertuin
                        voor OBS Oog in Al
                    </h2>
                    <div className='gap-12 grid grid-cols-1 lg:grid-cols-2 items-center'>
                        <p
                            className='grow shrink basis-0 text-dark'>Op
                            12 september 2012 is de permacultuur-leertuin bij basisschool Oog in Al te Utrecht
                            officieel geopend door wethouder Rinda den Besten (loco-burgemeester en wethouder
                            jeugd).
                        </p>
                        <img className='grow shrink basis-0 rounded-lg object-cover'
                             alt={'Openings door wethouder Rinda den Basten'} src='/images/projecten/obs/image.jpeg'/>
                    </div>
                    <div className='flex self-stretch justify-center sm:justify-end mt-[-28px] sm:mr-[-40px]'>
                        <a
                            className='px-7 py-2.5 w-11/12 sm:w-auto bg-green hover:scale-105 duration-300 rounded-full inline-flex text-light text-sm sm:text-lg font-semibold items-center justify-center' target={'_blank'} href={'/images/projecten/obs/artikelAD.jpeg'}>
                            Artikel AD
                            <svg xmlns='http://www.w3.org/2000/svg' className={'ml-2'} width='25' height='24'
                                 viewBox='0 0 25 24'
                                 fill='none'>
                                <path d='M5.5 12H19.5' stroke='white' strokeWidth='2' strokeLinecap='round'
                                      strokeLinejoin='round'/>
                                <path d='M12.5 5L19.5 12L12.5 19' stroke='white' strokeWidth='2'
                                      strokeLinecap='round' strokeLinejoin='round'/>
                            </svg>
                        </a>
                    </div>
                </div>
                <div className={'h-screen w-full'}>
                    <Carousel data={obs1.first} obs={'first'}/>
                </div>
                <div className='bg-green w-full'>
                    <div className={'max-w-screen-xl m-auto p-6 sm:p-12 fade-in'}>
                        <h2 className='text-light text-center'>“Hoe ons
                            grasveld een leertuin werd”
                        </h2>
                        <p className='text-light italic text-center mb-5'>(Wendelmoet
                            Kuipers, leerkracht obs Oog in Al)
                        </p>
                        <p className='text-light'>Als je de
                            tuin voor de Montessorischool nu ziet is het haast onvoorstelbaar dat dit vorig jaar nog een
                            grasveld was met een paar losse schooltuintjes erin.<br/>Een aantal jaar geleden was het
                            alleen
                            maar een grasveld en tuinierden onze kleuters bij de Kanaalweg op een terrein van de
                            gemeente
                            Utrecht. Het tuinieren daar was erg leuk, maar erheen en terug lopen met een groep kleuters
                            kostte altijd enorm veel tijd en er waren ook veel ouders voor nodig.
                        </p>
                    </div>
                </div>
                <div className={'h-screen w-full'}>
                    <Carousel data={obs1.second} obs={'mid'}/>
                </div>
                <div className='bg-green w-full'>
                    <div className={'max-w-screen-xl m-auto p-6 sm:p-12 fade-in'}>
                        <h2 className='text-light text-center mb-5'>Schooltuintjes</h2>
                        <p className='text-light'>Een jaar of drie geleden kwam van de ouderraad het voorstel om het
                            tuinieren te verplaatsen naar het grasveld voor de school. Zo bedacht, zo gedaan: de grond
                            werd getest op geschiktheid en een stuk of acht langwerpige schooltuintjes werden aangelegd.
                            De onderbouwjuffen en –ouders gingen vlijtig aan de slag met schepjes, gieters en zaaigoed
                            en binnen de kortste keren kookten we soep van groenten uit eigen tuin!
                        </p>
                    </div>
                </div>
                <div className={'h-screen w-full'}>
                    <Carousel data={obs1.third} obs={'mid'}/>
                </div>
                <div className='bg-green w-full'>
                    <div className={'max-w-screen-xl m-auto p-6 sm:p-12 fade-in'}>
                        <h2 className='text-light text-center mb-5'>Van schooltuintjes naar een grote schooltuin</h2>
                        <p className='text-light'>Eén van die ouders die met groepjes kinderen in de tuin kwam werken,
                            zonder twijfel de meest enthousiaste, was Ingeborg. Zij nam ruime kennis van en ervaring met
                            moestuinieren mee, maar ook een grote gedrevenheid om die kennis en vooral ervaring over te
                            brengen op de kinderen bij ons op school.<br/><br/>
                            Ingeborg had veel ideeën voor de tuin die als snel uitgroeiden tot een allesomvattend plan
                            voor een grote leertuin waarin niet alleen voor de kleuters maar voor alle kinderen veel te
                            doen, zien en ontdekken zou zijn.<br/><br/>
                            Na een fase van brainstormen en plannen maken met leerkrachten en een aantal ouders kwam er
                            een ontwerp. En binnen de kortste keren werden er steeds meer onderdelen uit het ontwerp
                            zichtbaar in onze tuin!
                        </p>
                        <div className='flex justify-center sm:justify-end mt-6'>
                            <a
                                className='px-7 py-2.5 bg-white w-11/12 sm:w-auto hover:scale-105 duration-300 rounded-full inline-flex text-dark text-sm sm:text-lg font-semibold leading-loose items-center justify-center' target={'_blank'} href={'/images/projecten/Artikel-in-Eetbaar-Utrecht.pdf'}>
                                Artikel Stadstuinkookboek
                                <svg xmlns='http://www.w3.org/2000/svg' className={'ml-2'} width='25' height='24'
                                     viewBox='0 0 25 24'
                                     fill='none'>
                                    <path d='M5.5 12H19.5' stroke='black' strokeWidth='2' strokeLinecap='round'
                                          strokeLinejoin='round'/>
                                    <path d='M12.5 5L19.5 12L12.5 19' stroke='black' strokeWidth='2'
                                          strokeLinecap='round' strokeLinejoin='round'/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div className={'h-screen w-full'}>
                    <Carousel data={obs1.fourth} obs={'mid'}/>
                </div>
                <div className='bg-green w-full'>
                    <div className={'max-w-screen-xl m-auto p-6 sm:p-12 fade-in'}>
                        <h2 className='text-light text-center mb-5'>Een echte permacultuur-<span className={'italic'}>leer</span>tuin</h2>
                        <p className='text-light'>Wat heel erg mooi is, is dat al voor de officiële ingebruikname de
                            tuin veel positiefs teweegbrengt: kinderen, ouders en leerkrachten genieten van de tuin als
                            plek om met de natuur bezig te zijn, als plek om samen te komen en te eten, als plek om
                            groente te verkopen en te kopen en als speelplek. En er wordt nu al ontzettend veel geleerd:
                            tuinaanleg (zo heb ik zelf geleerd hoe je wilgentenen moet vlechten), hoe je allerlei
                            planten moet zaaien, verzorgen en oogsten, wat compost is en hoe je het maakt, hoe al die
                            planten, kruiden en groentes heten (handig als je ze verkopen moet) en ga zo maar door.<br/><br/>
                            En het “officiële” onderwijs in de leertuin is eigenlijk nog niet eens begonnen! We hebben
                            allemaal plannen: kleutertuintjes waar ieder kind een ‘eigen’ tuintje kan hebben,
                            kaartenbakken met opdrachtkaarten waarmee oudere kinderen zelfstandig in de tuin kunnen
                            werken, buitenlessen op het pleintje achterin de tuin, kriebelbeestjes zoeken om te
                            bestuderen, groente oogsten om mee te koken. We hebben er heel veel zin in!
                        </p>
                        <div className='flex justify-center sm:justify-end mt-6'>
                            <a
                                className='px-7 py-2.5 w-11/12 sm:w-auto bg-white hover:scale-105 duration-300 rounded-full inline-flex text-dark text-sm sm:text-lg font-semibold leading-loose items-center justify-center' rel={'noreferrer'} target={'_blank'} href={'https://www.duic.nl/algemeen/proeftuinen-van-utrecht-2-een-recept-voor-de-leerlingen-juffen-en-meesters/'}>
                                Artikel DUIC
                                <svg xmlns='http://www.w3.org/2000/svg' className={'ml-2'} width='25' height='24'
                                     viewBox='0 0 25 24'
                                     fill='none'>
                                    <path d='M5.5 12H19.5' stroke='black' strokeWidth='2' strokeLinecap='round'
                                          strokeLinejoin='round'/>
                                    <path d='M12.5 5L19.5 12L12.5 19' stroke='black' strokeWidth='2'
                                          strokeLinecap='round' strokeLinejoin='round'/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from 'react-router-dom';


export default function() {
    const location = useLocation();
    const navigate = useNavigate ();

    const [show, setShow] = useState(true);
    const [visible, setVisible] = useState(false);
    const [subVisible, setSubVisible] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        if (window.scrollY > lastScrollY) {
            setShow(false);
        } else {
            setShow(true);
        }

        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    useEffect(() => {
        setVisible(false);
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <div className={`px-5 pt-5 flex-col flex w-full fixed z-20 overflow-hidden duration-300 ${show ? (visible ? 'h-[400px]' : 'h-[250px]') : 'h-0'}`}>
            <nav className={'bg-white rounded-3xl w-full shadow'}>
                <div className="p-6 justify-between items-center inline-flex w-full">
                    <div className="flex-col items-start inline-flex cursor-pointer" onClick={() => navigate('/')}>
                        <h3 className="text-green text-base sm:text-2xl font-semibold">Permacultuur-leertuinen</h3>
                        <p className="text-green leading-6 tracking-tight hidden sm:block">Wij ontwerpen en
                            realiseren permacultuur-leertuinen
                        </p>
                    </div>
                    <div className="items-center gap-5 hidden lg:flex">
                        <NavLink to={'/'} className={`font-semibold hover:text-green`}>Home</NavLink>
                        <div className="dropdown relative">
                            <NavLink to={'/permacultuur'} className={`item font-semibold`} onClick={(e) => e.preventDefault()}>Permacultuur</NavLink>
                            <div className={'dropdown-container absolute overflow-hidden'}>
                                <div className="h-10"/>
                                <div
                                    className="dropdown-content bg-white rounded-lg flex-col justify-center text-center items-center">
                                    <div
                                        className="dropdown-item justify-center items-center inline-flex w-full">
                                        <NavLink
                                            to={'/permacultuur'}
                                            end
                                            className="text-center px-5 py-2 text-base font-semibold w-full">Permacultuur
                                        </NavLink>
                                    </div>
                                    <div
                                        className="dropdown-item justify-center items-center inline-flex w-full">
                                        <NavLink
                                            to={'/permacultuur/leertuin'}
                                            className="text-center px-5 py-2 text-base font-semibold w-full whitespace-nowrap">Permacultuur-leertuin
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <NavLink to={'/projecten'} className={`font-semibold hover:text-green`}>Projecten</NavLink>
                        <NavLink className={({ isActive }) => `${isActive && 'bg-green text-light'} hover:bg-green hover:text-white px-7 py-2.5 rounded-full border border-green font-semibold`}
                           to={'/contact'}>
                            Contact
                        </NavLink>
                    </div>
                    <div className="lg:hidden cursor-pointer" onClick={() => visible ? setVisible(false) : setVisible(true)}>
                        <div className={`p-2 rounded-full border border-green duration-300 ${visible && 'bg-green'}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <path d="M18 10H6" className={'duration-300'} stroke={visible ? 'white' : 'black'}
                                      strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M21 6H3" className={'duration-300'} stroke={visible ? 'white' : 'black'} strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M21 14H3" className={'duration-300'} stroke={visible ? 'white' : 'black'} strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                                <path d="M18 18H6" className={'duration-300'} stroke={visible ? 'white' : 'black'} strokeWidth="2" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className={`flex-col items-center flex menu ${visible && 'menu-transition'}`}>
                    <div className="w-full border-t border-black inline-flex">
                        <NavLink to={'/'} className={'w-full text-center py-2.5'}>Home</NavLink>
                    </div>
                    <div
                        className="self-stretch py-2.5 border-t border-black justify-center items-center cursor-pointer inline-flex"
                        onClick={() => setSubVisible(!subVisible)}
                    >
                        <div>Permacultuur</div>
                        <div className="w-5 h-5 py-1 justify-center items-center flex">
                            {subVisible ? (
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"
                                     fill="none">
                                    <path d="M5.25 7.125L10 11.875L14.75 7.125" stroke="black" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                                     fill="none">
                                    <path d="M7.125 14.25L11.875 9.5L7.125 4.75" stroke="black" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            )}
                        </div>
                    </div>
                    <div
                        className={`submenu flex-col items-center w-full flex menu ${subVisible && 'sub-menu-transition'}`}>
                        <div className="w-full border-t inline-flex">
                            <NavLink to={'/permacultuur'} className={'w-full text-center py-2.5'} end>Permacultuur</NavLink>
                        </div>
                        <div className="w-full border-t inline-flex">
                            <NavLink to={'/permacultuur/leertuin'} className={'w-full text-center py-2.5'}>Permacultuur-leertuin</NavLink>
                        </div>
                    </div>
                    <div className="w-full border-t border-black inline-flex">
                        <NavLink to={'/projecten'} className={'w-full text-center py-2.5'}>Projecten</NavLink>
                    </div>
                    <div className="w-full border-t border-black inline-flex">
                        <NavLink to={'/contact'} className={'w-full text-center py-2.5'}>Contact</NavLink>
                    </div>
                </div>
            </nav>
        </div>
    );
}

import {Route, Routes, useLocation, Link} from 'react-router-dom';
import Home from "./home/Home.js";
import Permacultuur from "./permacultuur/Permacultuur";
import React from "react";
import NavBar from "./NavBar";
import Footer from "./Footer";
import IntersectionObserverProvider from "./IntersectionObserverProvider";
import PermacultuurLeertuin from "./permacultuur/PermacultuurLeertuin";
import Projecten from "./projecten/Projecten";
import Contact from "./contact/Contact";
import Contacted from "./contact/Contacted";
import OBSOoginAl from "./projecten/OBSOoginAl";
import Korenmaat from "./projecten/Korenmaat";
import Stadslandbouw from "./projecten/Stadslandbouw";
import Natuurclub from "./projecten/Natuurclub";
import NotFound from "./NotFound";

function App() {
    const location = useLocation();

  return (
      <IntersectionObserverProvider>
          <NavBar />
          <Routes>
              <Route path={'/'} element={<Home />} />
              <Route path={'/permacultuur'} element={<Permacultuur />} />
              <Route path={'/permacultuur/leertuin'} element={<PermacultuurLeertuin />} />
              <Route path={'/projecten'} element={<Projecten />} />
              <Route path={'/projecten/obs'} element={<OBSOoginAl />} />
              <Route path={'/projecten/korenmaat'} element={<Korenmaat />} />
              <Route path={'/projecten/natuurclub'} element={<Natuurclub />} />
              <Route path={'/projecten/stadslandbouw'} element={<Stadslandbouw />} />
              <Route path={'/contact'} element={<Contact />} />
              <Route path={'/contacted'} element={<Contacted />} />
              <Route path="*" element={<NotFound />} />
          </Routes>
          {location.pathname !== '/projecten' && <Footer/> }
      </IntersectionObserverProvider>
  );
}

export default App;

import React from 'react';

export default function() {
    return (
        <div className='bg-white flex-col justify-start items-center inline-flex w-full mb-12 sm:mb-24'>
            <div className={'relative sm:h-[700px] w-full'}>
                <img
                    src={'/images/permacultuur/header1.jpeg'}
                    alt={'Groenten in een permacultuur tuin'}
                    className={'duration-300 object-cover h-full w-full'}
                />
                <div className='gradient-overlay gradient-overlay-white'/>
            </div>
            <div className='max-w-screen-xl px-5 sm:px-12 mx-auto mb-12 sm:mb-24 fade-in'>
                <h2 className={'text-center mb-6'}>Wat is
                    permacultuur?
                </h2>
                <p>Permacultuur is
                    een methode om duurzame leefomgevingen te ontwerpen die de diversiteit, stabiliteit en
                    veerkracht vertonen van natuurlijke ecosystemen. Inspiratie wordt daarbij uit de natuur
                    gehaald.<br/><br/>Permacultuur
                    is samenwerken met de natuur om een betere wereld voor ons allemaal te maken. Als we de natuur
                    observeren zien we dat er een systeem van principes aan het werk is. Permacultuur gebruikt deze
                    principes om geïntegreerde systemen te ontwikkelen die voor onze behoeften aan voedsel, energie
                    en gemeenschap zorgen op een gezonde en efficiënte wijze. Door Permacultuur kunnen we de
                    kwaliteit en productiviteit van onze individuele levens, onze samenleving en onze omgeving
                    verbeteren.
                </p>
            </div>
            <div className='px-5 sm:px-12 mb-12 sm:mb-24'>
                <div className='grid grid-cols-1 sm:grid-cols-2 3xl:grid-cols-3 gap-5 auto-rows-auto fade-in'>
                    <h3
                        className='text-black text-2xl font-semibold leading-loose sm:col-span-2'>Permacultuur
                        kan op alle schalen toegepast worden
                    </h3>
                    <div
                        className='p-10 bg-green rounded-lg xl:block hidden xl:row-span-2 3xl:row-span-3'>
                        <h2 className={'text-light mb-5'}>Permacultuur
                            kan overal worden toegepast
                        </h2>
                        <p
                            className='text-light'>Zelfonderhoudende
                            systemen, samenwerkingsverbanden creëren, niet alleen in de tuin, maar ook in huis en in
                            de
                            samenleving behoren tot de essentie van Permacultuur. Een zelfonderhoudend systeem is
                            een
                            samenwerkingsverband tussen de verschillende elementen waarbij ‘afval’ van het ene
                            element
                            gebruikt wordt als ‘bron’ voor een ander element. Hoe beter het systeem werkt, hoe
                            minder
                            arbeid nodig is om het te laten functioneren en hoe minder afval er is.<br/><br/>Permacultuur
                            is voor iedereen en overal toepasbaar, zowel in de stad, op een balkon, als op het
                            platteland, op het niveau van gezin, gemeenten en steden, hier en in de tropische
                            streken.
                            De universele principes krijgen telkens weer een eigen, lokale invulling die beantwoordt
                            aan
                            de plaatselijke noden en omstandigheden.
                        </p>
                    </div>
                    <img className='rounded-lg w-full object-cover sm:h-96' alt={'Verschillende groenten in een permacultuur tuin'} src='/images/permacultuur/image1.jpeg'/>
                    <img className='rounded-lg w-full object-cover sm:h-96' alt={'Verschillende groenten verbouwd tegen en bij een klein stuk muur'} src='/images/permacultuur/image2.jpeg'/>
                    <img className='rounded-lg w-full object-cover sm:h-96' alt={'Groenten verbouwd op een klein stuk land'} src='/images/permacultuur/image3.jpeg'/>
                    <img className='rounded-lg w-full object-cover sm:h-96' alt={'Overzicht permacultuur tuin De Korenmaat'} src='/images/permacultuur/image4.jpeg'/>
                    <div
                        className='p-5 sm:p-12 bg-green rounded-lg xl:hidden sm:col-span-2'>
                        <h2 className={'text-light mb-5'}>Permacultuur
                            kan overal worden toegepast
                        </h2>
                        <p
                            className='text-light'>Zelfonderhoudende
                            systemen, samenwerkingsverbanden creëren, niet alleen in de tuin, maar ook in huis en in
                            de
                            samenleving behoren tot de essentie van Permacultuur. Een zelfonderhoudend systeem is
                            een
                            samenwerkingsverband tussen de verschillende elementen waarbij ‘afval’ van het ene
                            element
                            gebruikt wordt als ‘bron’ voor een ander element. Hoe beter het systeem werkt, hoe
                            minder
                            arbeid nodig is om het te laten functioneren en hoe minder afval er is.<br/><br/>Permacultuur
                            is voor iedereen en overal toepasbaar, zowel in de stad, op een balkon, als op het
                            platteland, op het niveau van gezin, gemeenten en steden, hier en in de tropische
                            streken.
                            De universele principes krijgen telkens weer een eigen, lokale invulling die beantwoordt
                            aan
                            de plaatselijke noden en omstandigheden.
                        </p>
                    </div>
                </div>
            </div>
            <div className='max-w-screen-xl px-5 sm:px-12 mb-12 sm:mb-24 fade-in'>
                <p>Permacultuur is een praktische toepassing van duurzaamheid en biedt
                    handvaten om zo een deel van de oplossing te kunnen zijn in plaats van dat je zelf onderdeel
                    bent van de problemen waarmee we te maken hebben en gaan krijgen.<br/><br/>Permacultuur is een
                    samenhangend kader dat goede ideeën, zowel traditionele als nieuwe, gebruikt om productieve,
                    arbeidsarme systemen te creëren die efficiënt energie benutten. Als een systeem optimaal werkt
                    is er geen extra energie-inbreng nodig van buiten uit. De zon is de enige energiebron waar we
                    niet buiten kunnen. Dan is ook het systeem duurzaam, want het kan blijven functioneren. Zo
                    voorzien we in de menselijke behoeften zonder de energiebronnen uit te putten.
                </p>
            </div>
            <div className='px-5 sm:px-12 container 2xl:items-center lg:inline-flex mb-12 sm:mb-24 fade-in'>
                <div className='lg:ml-[-50px] lg:hidden'>
                    <img className='w-full rounded-t-lg' alt={'Overzicht permacultuur tuin De Korenmaat'} src='/images/permacultuur/image5.jpeg'/>
                </div>
                <div className='p-5 sm:p-12 bg-green rounded-b-lg lg:rounded-lg z-10 lg:mr-[-50px] lg:mt-40 2xl:mt-0'>
                    <h2 className='text-light mb-5'>Voordelen van Permacultuur</h2>
                    <p className='text-white'>
                        Permacultuur stimuleert ons om zelfvoorzienend en vindingrijk te zijn. Met Permacultuur bouw je
                        als het ware een mozaïek van ecosystemen. Voor het werken in de tuin geldt dat na een aantal
                        jaren je alleen nog maar hoeft te oogsten en heeft de tuin nog maar weinig begeleiding nodig.
                        Daarnaast neemt de biodiversiteit toe wat weer leidt tot een sterker en vitaler ecosysteem voor
                        zowel de flora als de fauna.
                    </p>
                </div>
                <div className='lg:ml-[-50px] hidden lg:block'>
                    <img className='w-full rounded' alt={'Overzicht permacultuur tuin De Korenmaat'} src='/images/permacultuur/image5.jpeg'/>
                </div>
            </div>
            <p className='max-w-screen-xl px-5 sm:px-12 mb-12 sm:mb-24 fade-in'>Een hogere
                graad aan diversiteit van planten (kleuren en geuren) en dieren verhoogt ook de belevingsvreugde van
                de mensen die werken of genieten van de tuin. En uit wetenschappelijk onderzoek is gebleken dat het
                stresshormoon testosteron aanmerkelijk afneemt. Bij kinderen verhoogt het het leervermogen en zie je
                een heel ander gedrag vooral bij drukke kinderen of kinderen met een aandoening uit het autistische
                spectrum. Ze worden rustiger en kunnen zich beter concentreren.
            </p>
            <div className='px-5 sm:px-12 w-full'>
                <h2 className='mb-5 fade-in'>De
                    3 ethische principes van Permacultuur
                </h2>
                <div className='flex-col items-center flex fade-in'>
                    <img className={'sm:h-[860px] rounded-lg object-cover w-full'} alt={'Foto van permacultuur perk De Korenmaat'} src='/images/permacultuur/image6.jpeg'/>
                    <div className='grid grid-cols-1 lg:grid-cols-3 gap-2 lg:gap-14 mt-[-50px] sm:mt-[-100px] mx-5'>
                        <div
                            className='2xl:w-96 px-5 py-5 bg-green rounded-lg flex-col justify-start items-start inline-flex'>
                            <h3 className='text-light mb-2.5'>Zorg
                                voor de aarde
                            </h3>
                            <p
                                className='text-light'>Biodiversiteit
                                op gang brengen, lijnen kort houden en geen verspilling. Permacultuur is een
                                gesloten kringloop en werkt volgens de natuurlijke wetten en elementen. Er komt geen
                                chemie aan te pas en houdt rekening met de seizoenen. Je neemt niet meer dan je
                                nodig hebt. Je werkt met liefde met de aarde, de natuur. Permacultuur stimuleert de
                                creativiteit, maakt je bewust van je omgeving.
                            </p>
                        </div>
                        <div
                            className='2xl:w-96 px-5 py-5 bg-green rounded-lg flex-col justify-start items-start inline-flex'>
                            <h3 className='text-light mb-2.5'>Zorg
                                voor de mens
                            </h3>
                            <p
                                className='text-light'>Zorg
                                voor de mens is zorgen voor de aarde. De mens zit aan het einde van de voedselketen
                                en is een goede graadmeter hoe het met de natuur is gesteld. Permacultuur stimuleert
                                een sociale economie, betrokkenheid, zorg voor elkaar en geeft gezonde voeding.
                            </p>
                        </div>
                        <div
                            className='2xl:w-96 px-5 py-5 bg-green rounded-lg flex-col justify-start items-start inline-flex'>
                            <h3 className='text-light mb-2.5'>Delen
                                van de overvloed
                            </h3>
                            <p
                                className='text-light'>Permacultuur
                                staat voor leren ontvangen en kennis delen. Het stelt limieten aan consumptie; de
                                bronnen delen tussen aarde en mens. Producten worden hergebruikt en er is geen
                                verspilling. Overvloed wordt gedeeld met alle levende wezens.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
